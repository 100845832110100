import React from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Collapsible from '../../components/ux/collapsible/Collapsible'
import { SET_COOKIE_POPUP } from './../../actions/actionTypes'
import Toggle from '../../components/ux/toggle/toggle'
import { useCookies } from 'react-cookie'

function Privacy({ openCookiePopup, translations }) {
    const COOKIE_NAME = "bodyshake_etracker"
    const [cookies, setCookie] = useCookies(COOKIE_NAME)

    const etrackerClickedCallback = checked => {
        setCookie(COOKIE_NAME, checked ? 'on' : 'off', { path: "/", maxAge: 31536000 })
        console.log('etrackerClickedCallback ' + checked);
    }

    const openCookieOptions = e => {
        e.preventDefault();
        openCookiePopup(true)

    }


    return (
        <Layout>
            <SEO title={translations.privacy_title} description={translations.privacy_meta_desciption} />
            <div className="subpage plain-text-content">
                <div className="lightholder"></div>
                <section>
                    <div className="inner-wrapper mb-2 privacy">
                        <h1 className='mb-2'>
                            Privacy Policy
                        </h1>
                        <Collapsible title="General information" open>
                            <p className="light">
                                The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.<br /><br />
                                <span className="halyard bold">Who is the responsible party for the recording of data on this website (i.e. the “controller”)?</span><br />
                                The data on this website is processed by the operator of the website, whose contact information is available under section “Information Required by Law” on this website.<br /><br />
                                <span className="halyard bold">How do we collect your data? </span><br />
                                We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.<br /><br />
                                Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g. web browser, operating system or time the site was accessed). This information is recorded automatically when you access this website.<br /><br />
                                <span className="halyard bold">What are the purposes we use your data for?</span><br />
                                A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.<br /><br />
                                <span className="halyard bold">What rights do you have as far as your information is concerned?</span><br />
                                You have the right to receive information about the source, recipients and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing.<br /><br />
                                Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.<br /><br />
                                Please do not hesitate to contact us at any time under the address disclosed in section “Information Required by Law” on this website if you have questions about this or any other data protection related issues.<br /><br />
                                <span className="halyard bold">Analysis tools and tools provided by third parties</span><br />
                                There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs.<br /><br />
                                For detailed information about these analysis programs please consult our Data Protection Declaration below.
                            </p>
                        </Collapsible>
                        <Collapsible title="Hosting and Content Delivery Networks (CDN)">
                            <p className="t-upper mt mb bolder">
                                External Hosting
                            </p>
                            <p className="light">
                                This website is hosted by an external service provider (host). Personal data collected on this website are stored on the servers of the host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications, contract information, contact information, names, web page access, and other data generated through a web site. <br /><br />
                                The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 para. 1 lit. b GDPR) and in the interest of secure, fast and efficient provision of our online services by a professional provider (Art. 6 para. 1 lit. f GDPR).<br /><br />
                                Our host will only process your data to the extent necessary to fulfil its performance obligations and to follow our instructions with respect to such data.<br /><br />
                                We are using the following host:<br />
                                ALL-INKL.COM - Neue Medien Münnich <br />
                                Contact: René Münnich <br />
                                Hauptstraße 68<br />
                                02742 Friedersdorf <br />
                                Germany<br /><br />
                                <span className="halyard bold">Execution of a contract data processing agreement</span><br />
                                In order to guarantee processing in compliance with data protection regulations, we have concluded an order processing contract with our host.
                            </p>
                        </Collapsible>
                        <Collapsible title="General information and mandatory information">
                            <p className="t-upper mt mb bolder">Data protection</p>
                            The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.<br /><br />
                            Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.<br /><br />
                            We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access.
                            <p className="t-upper mt mb bolder">Information about the responsible party (referred to as the “controller” in the GDPR)</p>
                            The data processing controller on this website is:<br /><br />
                            Bodyshake GmbH <br />
                            Brauereistraße 45 <br />
                            08064 Zwickau <br />
                            Germany <br /><br />
                            Phone: +49 375 692 451 50 <br />
                            Email: <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#73;&#78;&#70;&#79;&#64;&#66;&#79;&#68;&#89;&#83;&#72;&#65;&#75;&#69;&#46;&#67;&#79;&#77;">&#73;&#78;&#70;&#79;&#64;&#66;&#79;&#68;&#89;&#83;&#72;&#65;&#75;&#69;&#46;&#67;&#79;&#77;</a> <br /><br />
                            The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g. names, e-mail addresses, etc.).
                            <p className="t-upper mt mb bolder">Storage duration</p>
                            Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g. tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.
                            <p className="t-upper mt mb bolder">Designation of a data protection officer as mandated by law</p>
                            We have appointed a data protection officer for our company.<br /><br />
                            Veit Günl <br />
                            Brauereistraße 45 <br />
                            08064 Zwickau <br />
                            Germany<br /><br />
                            Phone: +49 375 692 451 50 <br />
                            Email: <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#68;&#65;&#84;&#69;&#78;&#83;&#67;&#72;&#85;&#84;&#90;&#66;&#69;&#65;&#85;&#70;&#84;&#82;&#65;&#71;&#84;&#69;&#82;&#64;&#66;&#79;&#68;&#89;&#45;&#83;&#72;&#65;&#75;&#69;&#46;&#67;&#79;&#77;">&#68;&#65;&#84;&#69;&#78;&#83;&#67;&#72;&#85;&#84;&#90;&#66;&#69;&#65;&#85;&#70;&#84;&#82;&#65;&#71;&#84;&#69;&#82;&#64;&#66;&#79;&#68;&#89;&#45;&#83;&#72;&#65;&#75;&#69;&#46;&#67;&#79;&#77;</a>
                            <p className="t-upper mt mb bolder">Information on data transfer to the USA</p>
                            Our website uses, in particular, tools from companies based in the USA. When these tools are active, your personal information may be transferred to the US servers of these companies. We must point out that the USA is not a safe third country within the meaning of EU data protection law. US companies are required to release personal data to security authorities without you as the data subject being able to take legal action against this.<br /><br />
                            The possibility cannot therefore be excluded that US authorities (e.g. secret services) may process, evaluate and permanently store your data on US servers for monitoring purposes.  We have no influence over these processing activities.
                            <p className="t-upper mt mb bolder">Revocation of your consent to the processing of data </p>
                            A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.
                            <p className="t-upper mt mb bolder">Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</p>
                            In the event that data are processed on the basis of art. 6 sect. 1 lit. e or f GDPR, you have the right to at any time object to the processing of your personal data based on grounds arising from your unique situation. This also applies to any profiling based on these provisions. To determine the legal basis, on which any processing of data is based, please consult this data protection declaration. If you log an objection, we will no longer process your affected personal data, unless we are in a position to present compelling protection worthy grounds for the processing of your data, that outweigh your interests, rights and freedoms or if the purpose of the processing is the claiming, exercising or defence of legal entitlements (objection pursuant to art. 21 sect. 1 GDPR).<br /><br />
                            If your personal data is being processed in order to engage in direct advertising, you have the right to at any time object to the processing of your affected personal data for the purposes of such advertising. This also applies to profiling to the extent that it is affiliated with such direct advertising. If you object, your personal data will subsequently no longer be used for direct advertising purposes (objection pursuant to art. 21 sect. 2 GDPR).
                            <p className="t-upper mt mb bolder">Right to log a complaint with the competent supervisory agency</p>
                            In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.
                            <p className="t-upper mt mb bolder">Right to data portability</p>
                            You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.
                            <p className="t-upper mt mb bolder">SSL and/or TLS encryption</p>
                            For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.<br /><br />
                            If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
                            <p className="t-upper mt mb bolder">Encrypted payment transactions on this website</p>
                            If you are under an obligation to share your payment information (e.g. account number if you give us the authority to debit your bank account) with us after you have entered into a fee-based contract with us, this information is required to process payments.<br /><br />
                            Payment transactions using common modes of paying (Visa/MasterCard, debit to your bank account) are processed exclusively via encrypted SSL or TLS connections. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.<br /><br />
                            If the communication with us is encrypted, third parties will not be able to read the payment information you share with us.
                            <p className="t-upper mt mb bolder">Information about, rectification and eradication of data</p>
                            Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time at the address provided in section “Information Required by Law.”
                            <p className="t-upper mt mb bolder">Right to demand processing restrictions</p>
                            You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time at the address provided in section “Information Required by Law.” The right to demand restriction of processing applies in the following cases:<br /><br />
                            In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.<br /><br />
                            If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.<br /><br />
                            If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.<br /><br />
                            If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.<br /><br />
                            If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.
                            <p className="t-upper mt mb bolder">Rejection of unsolicited emails</p>
                            We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in section “Information Required by Law” to send us promotional and information material that we have not expressly requested. The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for instance via SPAM messages.
                        </Collapsible>
                        <Collapsible title="Recording of data on this website">
                            <p className="t-upper mt mb bolder">Cookies</p>
                            Our websites and pages use what the industry refers to as “cookies.” Cookies are small text files that do not cause any damage to your device. They are either stored temporarily for the duration of a session (session cookies) or they are permanently archived on your device (permanent cookies). Session cookies are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device until you actively delete them or they are automatically eradicated by your web browser.<br /><br />
                            In some cases, it is possible that third-party cookies are stored on your device once you enter our site (third-party cookies). These cookies enable you or us to take advantage of certain services offered by the third party (e.g. cookies for the processing of payment services).<br /><br />
                            Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the absence of the cookies (e.g. the shopping cart function or the display of videos). The purpose of other cookies may be the analysis of user patterns or the display of promotional messages.<br /><br />
                            Cookies, which are required for the performance of electronic communication transactions (required cookies) or for the provision of certain functions you want to use (functional cookies, e.g. for the shopping cart function) or those that are necessary for the optimization of the website (e.g. cookies that provide measurable insights into the web audience), shall be stored on the basis of Art. 6 Sect. 1 lit. f GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the storage of cookies to ensure the technically error free and optimized provision of the operator’s services. If your consent to the storage of the cookies has been requested, the respective cookies are stored exclusively on the basis of the consent obtained (Art. 6 Sect. 1 lit. a GDPR); this consent may be revoked at any time.<br /><br />
                            You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited.<br /><br />
                            In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.
                            <p className="t-upper mt mb bolder">Consent with Usercentrics</p>
                            This website uses the consent technology of Usercentrics to obtain your consent to the storage of certain cookies on your device or for the use of specific technologies, and to document the former in a data protection compliant manner. The party offering this technology is Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Germany, website: https://usercentrics.com/ (hereinafter referred to as “Usercentrics”). <br /><br />
                            Whenever you visit our website, the following personal data will be transferred to Usercentrics: <br />
                            <ul>
                                <li>Your declaration(s) of consent or your revocation of your declaration(s) of consent</li>
                                <li>Your IP address</li>
                                <li>Information about your browser</li>
                                <li>Information about your device</li>
                                <li>The date and time you visited our website</li>
                                <li>Geolocation</li>
                            </ul><br />
                            Moreover, Usercentrics shall store a cookie in your browser to be able to allocate your declaration(s) of consent or any revocations of the former. The data that are recorded in this manner shall be stored until you ask us to eradicate them, delete the Usercentrics cookie or until the purpose for archiving the data no longer exists. This shall be without prejudice to any mandatory legal retention periods.<br /><br />
                            The Usercentrics banner on this website has been configured with the assistance of eRecht24. This can be identified by the eRecht24 logo. To display the eRecht24 logo in the banner, a connection to the image server of eRecht24 will be established. In conjunction with this, the IP address is also transferred; however, is only stored in anonymized form in the server logs. The image server of eRecht24 is located in Germany with a German provider. The banner as such is provided exclusively by Usercentrics. <br /><br />
                            Usercentrics uses cookies to obtain the declarations of consent mandated by law. The legal basis for the use of specific technologies is Art. 6(1)(c) GDPR.
                            <br /><br />
                            <p className="t-upper">Data processing</p><br />
                            We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR.
                            <br /><br />
                            <p className="t-upper mt mb bolder">Overview of used Cookies</p>
                            <span className="halyard bold">Required Cookies</span>
                            Session cookie (provider: bodyshake.com, purpose: connection to the website, storage duration: end of session)<br /><br />
                            Cookie notice (provider: bodyshake.com, purpose: storage of the user's decision to use analytical and marketing cookies, storage period: 1 month)<br /><br />
                            Google Maps notice (provider: bodyshake.com, purpose: storage of the user decision to display the route map with Google Maps, storage duration: end of session)<br /><br />
                            <span className="halyard bold">Analytical Cookies</span>
                            Google Analytics (provider: Google, purpose: collection of pseudonymous usage statistics for the website after the user's consent, storage period: 24 months, objection links: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer noopener">https://tools.google.com/dlpage/gaoptout</a> - only for desktop / <a className="pumuckl" href="/privacy" onClick={openCookieOptions}>change the decision in the cookie notice</a> - for all devices)<br /><br />
                            Google Maps (provider: Google, purpose: collection of pseudonymous usage statistics for Google Maps after the consent of the user, storage period: 24 months)<br /><br />
                            YouTube (provider: YouTube, purpose: collection of pseudonymous usage statistics for YouTube after the user's consent, storage period: 24 months)<br /><br />
                            <span className="halyard bold">Marketing Cookies </span>
                            Google Ads Remarketing (provider: Google, purpose: is used by Google to display a range of advertising products, for example real-time bids from third-party advertisers; storage period: 3 months)<br /><br />
                            Facebook Pixel (provider: Facebook, purpose: is used by Facebook to display a range of advertising products, for example real-time bids from third-party advertisers; storage period: 3 months)
                            <p className="t-upper mt mb bolder">Server log files</p>
                            The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises: <br /><br />
                            The type and version of browser used<br />
                            The used operating system<br />
                            Referrer URL<br />
                            The hostname of the accessing computer<br />
                            The time of the server inquiry<br />
                            The IP address<br /><br />
                            This data is not merged with other data sources. This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the website has a legitimate interest in the technically error free depiction and the optimization of the operator’s website. In order to achieve this, server log files must be recorded.
                            <p className="t-upper mt mb bolder">Contact form </p>
                            If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent.<br /><br />
                            The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your agreement (Art. 6 Para. 1 lit. a GDPR) if this has been requested.<br /><br />
                            The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g. after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions – in particular retention periods.
                            <p className="t-upper mt mb bolder">
                                Request by e-mail, telephone or fax
                            </p>
                            If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.<br /><br />
                            These data are processed on the basis of Art. 6 Sect. 1 lit. b GDPR if your inquiry is related to the fulfillment of a contract or is required for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art. 6 Sect. 1 lit. f GDPR) or on the basis of your consent (Art. 6 Sect. 1 lit. a GDPR) if it has been obtained.<br /><br />
                            The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.
                        </Collapsible>
                        <Collapsible title="Analysis tools and advertising">

                            <p className="t-upper mt mb bolder">Google Tag Manager</p>
                            We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland<br /><br />
                            The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other technologies on our website. The Google Tag Manager itself does not create any user profiles, does not store cookies, and does not carry out any independent analyses. It only manages and runs the tools integrated via it. However, the Google Tag Manager does collect your IP address, which may also be transferred to Google’s parent company in the United States.<br /><br />
                            The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the quick and uncomplicated integration and administration of various tools on his website. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.<br /><br />
                            <p className="t-upper mt bolder mb">Use of SalesViewer® technology</p>
                            This website uses SalesViewer® technology from SalesViewer® GmbH on the basis of the website operator’s legitimate interests (Section 6 paragraph 1 lit.f GDPR) in order to collect and save data on marketing, market research and optimisation purposes.<br /><br />
                            In order to do this, a javascript based code, which serves to capture company-related data and according website usage. The data captured using this technology are encrypted in a non-retrievable one-way function (so-called hashing). The data is immediately pseudonymised and is not used to identify website visitors personally.<br /><br />
                            The data stored by Salesviewer will be deleted as soon as they are no longer required for their intended purpose and there are no legal obligations to retain them.<br /><br />
                            The data recording and storage can be repealed at any time with immediate effect for the future, by clicking on <a href="https://www.salesviewer.com/opt-out" target="_blank" rel="noreferrer noopener">https://www.salesviewer.com/opt-out</a> in order to prevent SalesViewer® from recording your data. In this case, an opt-out cookie for this website is saved on your device. If you delete the cookies in the browser, you will need to click on this link again.<br /><br />

                            <p className="t-upper mt mb bolder">Google Analytics</p>
                            This website uses functions of the web analysis service Google Analytics. The provider of this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized operating system and the user’s origin. This data is assigned to the respective end device of the user. An assignment to a user-ID does not take place.<br /><br />
                            Furthermore, Google Analytics allows us to record your mouse and scroll movements and clicks, among other things. Google Analytics uses various modeling approaches to augment the collected data sets and uses machine learning technologies in data analysis.<br /><br />
                            Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is, as a rule transferred to a Google server in the United States, where it is stored.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://privacy.google.com/businesses/controllerterms/mccs/">https://privacy.google.com/businesses/controllerterms/mccs/</a>.<br /><br />

                            <span className="halyard bold">Browser plug-in</span>
                            You can prevent the recording and processing of your data by Google by downloading and installing the browser plugin available under the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer noopener">https://tools.google.com/dlpage/gaoptout?hl=en</a>.<br /><br />
                            For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration at: <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noreferrer noopener">https://support.google.com/analytics/answer/6004245?hl=en</a>.<br /><br />

                            <span className="halyard bold">Google Signals</span>
                            We use Google Signals. Whenever you visit our website, Google Analytics records, among other things, your location, the progression of your search and YouTube progression as well as demographic data (site visitor data). This data may be used for customized advertising with the assistance of Google Signal. If you have a Google account, your site visitor information will be linked to your Google account by Google Signal and used to send you customized promotional messages. The data is also used to compile anonymized statistics of our users’ online patterns.<br /><br />

                            <span className="halyard bold">Demographic parameters provided by Google Analytics</span>
                            This website uses the “demographic characteristics” function of Google Analytics, to be able to display to the website visitor compatible ads within the Google advertising network. This allows reports to be created that contain information about the age, gender, and interests of the website visitors. The sources of this information are interest-related advertising by Google as well as visitor data obtained from third-party providers. This data cannot be allocated to a specific individual. You have the option to deactivate this function at any time by making pertinent settings changes for advertising in your Google account or you can generally prohibit the recording of your data by Google Analytics as explained in section “Objection to the recording of data”.<br /><br />

                            <span className="halyard bold">Contract data processing</span>
                            We have executed a contract data processing agreement with Google and are implementing the stringent provisions of the German data protection agencies to the fullest when using Google Analytics.<br /><br />
                            You can view this agreement at the following link: <a href="https://privacy.google.com/businesses/processorterms/" target="_blank" rel="noreferrer noopener">https://privacy.google.com/businesses/processorterms/</a>.<br /><br />

                            <p className="t-upper mt mb bolder">etracker</p>
                            This website uses the analysis service etracker. The provider of this service is the etracker GmbH, Erste Brunnenstraße 1, 20459 Hamburg, Germany.<br /><br />
                            etracker allows us to analyze the behavior patterns of our website visitors. For this purpose, etracker, among other things, records your abridged IP-address, geographic information (does not exceed details such as the city level), log files and other information your browser transfers to our webserver when you access the website. As a result, we are able to measure the website interactions, such as the length of the visit, conversions (e.g., registrations, purchase orders), scroll events, clicks and page access by the website visitor. These interactions are allocated to the website visitor for the duration of the current day, so that the data can be recognized during follow-up visits. Once the day has ended, visitor recognition is no longer possible.<br /><br />
                            No cookies will be stored in your browser in the absence of your consent. Moreover, no information is read in the archive of your device. Using this analysis tools without cookies occurs on the basis of Art. 6(1)(f) GDPR. The website operator has legitimate interest in the analysis of user patterns so that the operator can optimize the web portfolio and the ads. The rights and principal liberties of the data subject are protected. During the analysis with etracker, the IP address is anonymized as soon as possible, and the recognition of visitors is possible only for the duration of the current day.<br /><br />
                            If your respective consent has been obtained, processing will occur exclusively on the basis of Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />

                            You have the option to deactivate etracker here:<br /><br />

                            <Toggle
                                opts={{ 'data-tld': 'automat.bodyshake.com', id: 'et-opt-out', name: 'etracker' }}
                                labelText={translations.privacy_etracker_activate}
                                labelTextChecked={translations.privacy_etracker_deactivate}
                                clickHandlerCallback={etrackerClickedCallback}
                                checked={cookies[COOKIE_NAME] === 'on'} /> <br /><br />

                            <span className="halyard bold">Data processing</span><br />
                            We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR.<br /><br />

                            <p className="t-upper mt mb bolder">Google Ads</p>
                            The website operator uses Google Ads. Google Ads is an online promotional program of Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            Google Ads enables us to display ads in the Google search engine or on third-party websites, if the user enters certain search terms into Google (keyword targeting). It is also possible to place targeted ads based on the user data Google has in its possession (e.g., location data and interests; target group targeting). As the website operator, we can analyze these data quantitatively, for instance by analyzing which search terms resulted in the display of our ads and how many ads led to respective clicks.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://policies.google.com/privacy/frameworks" rel="noreferrer noopener" target="_blank">https://policies.google.com/privacy/frameworks</a> and <a href="https://privacy.google.com/businesses/controllerterms/mccs/" rel="noreferrer noopener" target="_blank">https://privacy.google.com/businesses/controllerterms/mccs/</a>.<br /><br />

                            <p className="t-upper mt mb bolder">Google Ads Remarketing</p>
                            This website uses the functions of Google Ads Remarketing. The provider of these solutions is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            With Google Ads Remarketing, we can assign people who interact with our online offering to specific target groups in order to subsequently display interest-based advertising to them in the Google advertising network (remarketing or retargeting).<br /><br />
                            Moreover, it is possible to link the advertising target groups generated with Google Ads Remarketing to device encompassing functions of Google. This makes it possible to display interest-based customized advertising messages, depending on your prior usage and browsing patterns on a device (e.g., cell phone) in a manner tailored to you as well as on any of your devices (e.g., tablet or PC).<br /><br />
                            If you have a Google account, you have the option to object to personalized advertising under the following link: <a href="https://www.google.com/settings/ads/onweb/" target="_blank" rel="noreferrer noopener">https://www.google.com/settings/ads/onweb/</a>.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            For further information and the pertinent data protection regulations, please consult the Data Privacy Policies of Google at: <a href="https://policies.google.com/technologies/ads?hl=en" target="_blank" rel="noreferrer noopener">https://policies.google.com/technologies/ads?hl=en</a>.<br /><br />

                            <p className="t-upper mt mb bolder">Formation of Target Groups with Customer Reconciliation</p>
                            For the formation of target groups, we use, among other things, the Google Ads Remarketing customer reconciliation feature. To achieve this, we transfer certain customer data (e.g., email addresses) from our customer lists to Google. If the respective customers are Google users and are logged into their Google accounts, matching advertising messages within the Google network (e.g., YouTube, Gmail or in a search engine) are displayed for them to view.<br /><br />

                            <p className="t-upper mt mb bolder">Google Conversion-Tracking</p>
                            This website uses Google Conversion Tracking. The provider of this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            With the assistance of Google Conversion Tracking, we are in a position to recognize whether the user has completed certain actions. For instance, we can analyze the how frequently which buttons on our website have been clicked and which products are reviewed or purchased with particular frequency. The purpose of this information is to compile conversion statistics. We learn how many users have clicked on our ads and which actions they have completed. We do not receive any information that would allow us to personally identify the users. Google as such uses cookies or comparable recognition technologies for identification purposes.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            For more information about Google Conversion Tracking, please review Google’s data protection policy at: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy?hl=en</a>.<br /><br />

                            <p className="t-upper mt mb bolder">Meta-Pixel (formerly Facebook Pixel)</p>
                            To measure conversion rates, this website uses the visitor activity pixel of Facebook/Meta. The provider of this service is Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. According to Facebook’s statement the collected data will be transferred to the USA and other third-party countries too.<br /><br />
                            This tool allows the tracking of page visitors after they have been linked to the website of the provider after clicking on a Facebook ad. This makes it possible to analyze the effectiveness of Facebook ads for statistical and market research purposes and to optimize future advertising campaigns.<br /><br />
                            For us as the operators of this website, the collected data is anonymous. We are not in a position to arrive at any conclusions as to the identity of users. However, Facebook archives the information and processes it, so that it is possible to make a connection to the respective user profile and Facebook is in a position to use the data for its own promotional purposes in compliance with the Facebook Data Usage Policy (https://www.facebook.com/about/privacy/). This enables Facebook to display ads on Facebook pages as well as in locations outside of Facebook. We as the operator of this website have no control over the use of such data.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            Within the meta pixel, we are using the expanded alignment function.<br /><br />
                            The expanded alignment allows us to transfer to Meta (Facebook) different types of data (e.g., place of residence, federal state, zip code, hashed email addresses, names, gender, date of birth or phone number) of our customers and prospects we collect through our website. As a result of this activation, we can tailor the offers presented in our advertising campaigns on Facebook to individuals interested in what we offer even more precisely. Moreover, this expanded alignment optimizes the allocation of website conversions and expands custom audiences.<br /><br />
                            Insofar as personal data is collected on our website with the help of the tool described here and forwarded to Facebook, we and Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland are jointly responsible for this data processing (Art. 26 DSGVO). The joint responsibility is limited exclusively to the collection of the data and its forwarding to Facebook. The processing by Facebook that takes place after the onward transfer is not part of the joint responsibility. The obligations incumbent on us jointly have been set out in a joint processing agreement. The wording of the agreement can be found under: https://www.facebook.com/legal/controller_addendum. According to this agreement, we are responsible for providing the privacy information when using the Facebook tool and for the privacy-secure implementation of the tool on our website. Facebook is responsible for the data security of Facebook products. You can assert data subject rights (e.g., requests for information) regarding data processed by Facebook directly with Facebook. If you assert the data subject rights with us, we are obliged to forward them to Facebook.<br /><br />
                            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/EU_data_transfer_addendum</a> and <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noreferrer noopener">https://de-de.facebook.com/help/566994660333381</a>.<br /><br />
                            In Facebook’s Data Privacy Policies, you will find additional information about the protection of your privacy at: https://www.facebook.com/about/privacy/.
                            You also have the option to deactivate the remarketing function “Custom Audiences” in the ad settings section under <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noreferrer noopener">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> . To do this, you first have to log into Facebook.<br /><br />
                            If you do not have a Facebook account, you can deactivate any user-based advertising by Facebook on the website of the European Interactive Digital Advertising Alliance: <a href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank" rel="noreferrer noopener">http://www.youronlinechoices.com/de/praferenzmanagement/</a>.<br /><br />

                            <p className="t-upper mt mb bolder">Facebook Custom Audiences</p>
                            We use Facebook Custom Audiences. The provider of this service is Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland.<br /><br />
                            Whenever you visit or use our website and apps, utilize our portfolio (e.g., participation in sweepstakes), transfer data to us or interact with the Facebook content of our company, we record related personal data. In the event that you have given us your consent to the use of Facebook Custom Audiences, we will share these data with Facebook to put Facebook in a position to send you compatible ads. These data may also be used to defined target audiences (Lookalike Audiences).<br /><br />
                            Facebook processes these data as our contract processor. For details, please consult the user agreement of Facebook: <a href="https://www.facebook.com/legal/terms/customaudience" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/customaudience</a>.<br /><br />
                            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.<br /><br />
                            The transfer of date to the USA is based on the standard contract clauses of the EU Commission. For details please see: <a href="https://www.facebook.com/legal/terms/customaudience" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/customaudience</a> and <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/dataprocessing</a>.<br /><br />


                        </Collapsible>
                        <Collapsible title="Plugins and Tools">
                            <p className="t-upper mt mb bolder">YouTube with expanded data protection integration</p>
                            Our website embeds videos of the website YouTube. The website operator is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            We use YouTube in the expanded data protection mode. According to YouTube, this mode ensures that YouTube does not store any information about visitors to this website before they watch the video. Nevertheless, this does not necessarily mean that the sharing of data with YouTube partners can be ruled out as a result of the expanded data protection mode. For instance, regardless of whether you are watching a video, YouTube will always establish a connection with the Google DoubleClick network.<br /><br />
                            As soon as you start to play a YouTube video on this website, a connection to YouTube’s servers will be established. As a result, the YouTube server will be notified, which of our pages you have visited. If you are logged into your YouTube account while you visit our site, you enable YouTube to directly allocate your browsing patterns to your personal profile. You have the option to prevent this by logging out of your YouTube account.<br /><br />
                            Furthermore, after you have started to play a video, YouTube will be able to place various cookies on your device or comparable technologies for recognition (e.g. device fingerprinting). In this way YouTube will be able to obtain information about this website’s visitors. Among other things, this information will be used to generate video statistics with the aim of improving the user friendliness of the site and to prevent attempts to commit fraud.<br /><br />
                            Under certain circumstances, additional data processing transactions may be triggered after you have started to play a YouTube video, which are beyond our control.<br /><br />
                            The use of YouTube is based on our interest in presenting our online content in an appealing manner. Pursuant to Art. 6 Sect. 1 lit. f GDPR, this is a legitimate interest. If a corresponding agreement has been requested, the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.<br /><br />
                            For more information on how YouTube handles user data, please consult the YouTube Data Privacy Policy under:
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a>
                            <p className="t-upper mt mb bolder">Adobe Fonts</p>
                            In order to ensure the uniform depiction of certain fonts, this website uses fonts called Adobe Fonts provided by Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe).<br /><br />
                            When you access pages of this website, your browser will automatically load the required fonts directly from the Adobe site to be able to display them correctly on your device. As a result, your browser will establish a connection with Adobe’s servers in the United States. Hence, Adobe learns that your IP address was used to access this website. According to the information provided by Adobe, no cookies will be stored in conjunction with the provision of the fonts.<br /><br />
                            Data are stored and analyzed on the basis of Art. 6 Sect. 1 lit. f GDPR. The website operator has a legitimate interest in a uniform presentation of the font on the operator’s website. If a respective declaration of consent has been obtained (e.g. consent to the archiving of cookies), the data will be processed exclusively on the basis of Art. 6 Sect. 1 lit. a DGDPR. Any such consent may be revoked at any time.
                            For more information about Adobe Fonts, please read the policies under:
                            <a href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html" target="_blank" rel="noreferrer noopener">https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a> <br /><br />
                            Adobe’s Data Privacy Declaration may be reviewed under:
                            <a href="https://www.adobe.com/de/privacy/policy.html" target="_blank" rel="noreferrer noopener">https://www.adobe.com/de/privacy/policy.html</a>
                            <p className="t-upper mt mb bolder">Google Maps</p>
                            This website uses the mapping service Google Maps. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                            To enable the use of the Google Maps features, your IP address must be stored. As a rule, this information is transferred to one of Google’s servers in the United States, where it is archived. The operator of this website has no control over the data transfer.<br /><br />
                            We use Google Maps to present our online content in an appealing manner and to make the locations disclosed on our website easy to find. This constitutes a legitimate interest as defined in Art. 6 Sect. 1 lit. f GDPR. If a respective declaration of consent has been obtained, the data shall be processed exclusively on the basis of Art. 6 Sect. 1 lit. a GDPR. This declaration of consent may be revoked at any time.
                            For more information on the handling of user data, please review Google’s Data Privacy Declaration under:
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a>
                        </Collapsible>
                        <Collapsible title="Custom Services">
                            <p className="t-upper mt mb bolder">Job Applications</p>
                            We offer website visitors the opportunity to submit job applications to us (e.g. via e-mail, via postal services or by submitting the online job application form). Below, we will brief you on the scope, purpose and use of the personal data collected from you in conjunction with the application process. We assure you that the collection, processing and use of your data will occur in compliance with the applicable data privacy rights and all other statutory provisions and that your data will always be treated as strictly confidential. <br /><br />
                            <span className="halyard bold">Scope and purpose of the collection of data </span>
                            If you submit a job application to us, we will process any affiliated personal data (e.g. contact and communications data, application documents, notes taken during job interviews, etc.), if they are required to make a decision concerning the establishment or an employment relationship. The legal grounds for the aforementioned are § 26 New GDPR according to German Law (Negotiation of an Employment Relationship), Art. 6 Sect. 1 lit. b GDPR (General Contract Negotiations) and – provided you have given us your consent – Art. 6 Sect. 1 lit. a GDPR. You may revoke any consent given at any time. Within our company, your personal data will only be shared with individuals who are involved in the processing of your job application.<br /><br />
                            If your job application should result in your recruitment, the data you have submitted will be archived on the grounds of § 26 New GDPR and Art. 6 Sect. 1 lit. b GDPR for the purpose of implementing the employment relationship in our data processing system.<br /><br />
                            <span className="halyard bold">Data Archiving Period</span>
                            If we are unable to make you a job offer or you reject a job offer or withdraw your application, we reserve the right to retain the data you have submitted on the basis of our legitimate interests (Art. 6 para. 1 lit. f GDPR) for up to 6 months from the end of the application procedure (rejection or withdrawal of the application). Afterwards the data will be deleted, and the physical application documents will be destroyed. The storage serves in particular as evidence in the event of a legal dispute. If it is evident that the data will be required after the expiry of the 6-month period (e.g. due to an impending or pending legal dispute), deletion will only take place when the purpose for further storage no longer applies.<br /><br />
                            Longer storage may also take place if you have given your agreement (Article 6 (1) (a) GDPR) or if statutory data retention requirements preclude the deletion.<br /><br />
                            <span className="halyard bold">Admission to the applicant pool</span>
                            Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die Möglichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der Aufnahme werden alle Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool übernommen, um Sie im Falle von passenden Vakanzen zu kontaktieren. <br /><br />
                            If we do not make you a job offer, you may be able to join our applicant pool. In case of admission, all documents and information from the application will be transferred to the applicant pool in order to contact you in case of suitable vacancies.<br /><br />
                            Admission to the applicant pool is based exclusively on your express agreement (Art. 6 para. 1 lit. a GDPR). The submission agreement is voluntary and has no relation to the ongoing application procedure. The affected person can revoke his agreement at any time. In this case, the data from the applicant pool will be irrevocably deleted, provided there are no legal reasons for storage.<br /><br />
                            The data from the applicant pool will be irrevocably deleted no later than two years after consent has been granted.
                        </Collapsible>
                        <Collapsible title="Our social media appearances">
                            <p className="t-upper mt mb bolder">Data processing through social networks</p>
                            We maintain publicly available profiles in social networks. The individual social networks we use can be found below.<br /><br />
                            Social networks such as Facebook, Google+ etc. can generally analyse your user behaviour comprehensively if you visit their website or a website with integrated social media content (e.g. like buttons or banner ads). When you visit our social media pages, numerous data protection-relevant processing operations are triggered. In detail:<br /><br />
                            If you are logged in to your social media account and visit our social media page, the operator of the social media portal can assign this visit to your user account. Under certain circumstances, your personal data may also be recorded if you are not logged in or do not have an account with the respective social media portal. In this case, this data is collected, for example, via cookies stored on your device or by recording your IP address.<br /><br />
                            Using the data collected in this way, the operators of the social media portals can create user profiles in which their preferences and interests are stored. This way you can see interest-based advertising inside and outside of your social media presence. If you have an account with the social network, interest-based advertising can be displayed on any device you are logged in to or have logged in to.<br /><br />
                            Please also note that we cannot retrace all processing operations on the social media portals. Depending on the provider, additional processing operations may therefore be carried out by the operators of the social media portals. Details can be found in the terms of use and privacy policy of the respective social media portals.<br /><br />
                            <span className="halyard bold">Legal basis</span>
                            Our social media appearances should ensure the widest possible presence on the Internet. This is a legitimate interest within the meaning of Art. 6 (1) lit. f GDPR. The analysis processes initiated by the social networks may be based on divergent legal bases to be specified by the operators of the social networks (e.g. consent within the meaning of Art. 6 (1) (a) GDPR).<br /><br />
                            <span className="halyard bold">
                                Responsibility and assertion of rights
                            </span>
                            If you visit one of our social media sites (e.g., Facebook), we, together with the operator of the social media platform, are responsible for the data processing operations triggered during this visit. You can in principle protect your rights (information, correction, deletion, limitation of processing, data portability and complaint) vis-à-vis us as well as vis-à-vis the operator of the respective social media portal (e.g. Facebook). <br /><br />
                            Please note that despite the shared responsibility with the social media portal operators, we do not have full influence on the data processing operations of the social media portals. Our options are determined by the company policy of the respective provider.<br /><br />
                            <span className="halyard bold">
                                Storage time
                            </span>
                            The data collected directly from us via the social media presence will be deleted from our systems as soon as the purpose for their storage lapses, you ask us to delete it, you revoke your consent to the storage or the purpose for the data storage lapses. Stored cookies remain on your device until you delete them. Mandatory statutory provisions - in particular, retention periods - remain unaffected.<br /><br />
                            We have no control over the storage duration of your data that are stored by the social network operators for their own purposes. For details, please contact the social network operators directly (e.g. in their privacy policy, see below).
                        </Collapsible>
                        <Collapsible title="Individual social networks" borderBottom>
                            <p className="t-upper mt mb bolder">Facebook</p>
                            We have a profile on Facebook. The provider of this service is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. According to Facebook’s statement the collected data will also be transferred to the USA and to other third party countries.<br /><br />
                            We have signed an agreement with Facebook on shared responsibility for the processing of data (Controller Addendum). This agreement determines which data processing operations we or Facebook are responsible for when you visit our Facebook Fanpage. This agreement can be viewed at the following link:
                            <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/page_controller_addendum</a> <br /><br />
                            You can customise your advertising settings independently in your user account. Click on the following link and log in:
                            <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noreferrer noopener">https://www.facebook.com/settings?tab=ads</a><br /><br />
                            Details can be found in Facebook private policy:
                            <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer noopener">https://www.facebook.com/about/privacy</a><br />
                            Account Settings (opt-out):
                            <a href="https://www.facebook.com/settings" target="_blank" rel="noreferrer noopener">https://www.facebook.com/settings</a><br />
                            Privacy Shield:
                            <a href="https://www.privacyshield.gov/participant" target="_blank" rel="noreferrer noopener">https://www.privacyshield.gov/participant</a>
                            <p className="t-upper mt mb bolder">Twitter</p>
                            We use the short message service Twitter. The provider is Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Ireand.<br /><br />
                            You can customise your Twitter privacy settings in your user account. Click on the following link and log in:
                            <a href="https://twitter.com/personalization" target="_blank" rel="noreferrer noopener">https://twitter.com/personalization</a><br /><br />
                            For details, see the Twitter Privacy Policy:
                            <a href="https://twitter.com/en/privacy" target="_blank" rel="noreferrer noopener">https://twitter.com/en/privacy</a> <br />
                            Account Settings (opt-out):
                            <a href="http://twitter.com/settings/account" target="_blank" rel="noreferrer noopener">http://twitter.com/settings/account</a> <br />
                            Privacy Shield:
                            <a href="http://www.privacyshield.gov/participant" target="_blank" rel="noreferrer noopener">http://www.privacyshield.gov/participant</a>
                            <p className="t-upper mt mb bolder">LinkedIn </p>
                            We have a LinkedIn profile. The provider is the LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland. LinkedIn is certified under the EU-US Privacy Shield. LinkedIn uses advertising cookies.<br /><br />
                            If you want to disable LinkedIn advertising cookies, please use the following link:
                            <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a> <br /><br />
                            For details on how they handle your personal information, please refer to LinkedIn's privacy policy:
                            <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/legal/privacy-policy</a><br />
                            Account Settings (opt-out): <a href="https://www.linkedin.com/psettings/privacy" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/psettings/privacy</a><br />
                            Privacy Shield: <a href="https://www.privacyshield.gov/participant" target="_blank" rel="noreferrer noopener">https://www.privacyshield.gov/participant</a>
                            <p className="t-upper mt mb bolder">Instagram </p>
                            We have a profile on Instagram. The provider is Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.<br /><br />
                            For details on how they handle your personal information, see the Instagram Privacy Policy:
                            <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer noopener">https://help.instagram.com/519522125107875</a><br />
                            Account Settings (opt-out):
                            <a href="http://www.instagram.com/accounts/privacy_and_security" target="_blank" rel="noreferrer noopener">http://www.instagram.com/accounts/privacy_and_security</a>
                            <p className="t-upper mt mb bolder">YouTube</p>
                            We have a profile on YouTube. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.<br /><br />
                            Privacy Policy:
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a><br />
                            Account Settings (opt-out):
                            <a href="http://adssettings.google.com/authenticated" target="_blank" rel="noreferrer noopener">http://adssettings.google.com/authenticated</a><br />
                            Privacy Shield:
                            <a href="http://www.privacyshield.gov/participant" target="_blank" rel="noreferrer noopener">http://www.privacyshield.gov/participant</a>
                            <p className="t-upper mt mb bolder">TikTok</p>
                            We have a profile on YouTube. The provider is TikTok Technology Limited, 10 Earlsfort Terrace,Dublin, D02 T380, Ireland.<br /><br />
                            For details on how they handle your personal information, see the TikTok Privacy Policy:
                            <a href="https://www.tiktok.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">https://www.tiktok.com/legal/privacy-policy</a>
                        </Collapsible>
                    </div>
                </section>
            </div>
        </Layout>
    )
}


const mapDispatchToProps = (dispatch) => ({
    openCookiePopup: () => {
        dispatch({ type: SET_COOKIE_POPUP, payload: true })
    }
})

const mapStateToProps = state => ({
    translations: state.preferences.translations,
})

export default connect(mapStateToProps, mapDispatchToProps)(Privacy)
